import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { makeStyles, FormControlLabel, Switch, FormGroup, Checkbox } from '@material-ui/core'
import { PersonAdd } from '@mui/icons-material'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import InputsUser from './components/InputsUser'
import InputsPhysicalProfile from './components/InputsPhysicalProfile'
import InputsLegalProfile from './components/InputsLegalProfile'
import Loader from 'components/Loader/Loader'
import InvestorRegisterTitles from './components/InvestorRegisterTitles'
import { matchPersonType, matchGender } from 'shared/matchData/matchData'
import parseJwt from 'shared/middlewares/parseJwt'
import { decidePerson } from 'shared/helpers/decide-person'
import { USER_LOGIN_SUCCESS } from 'local_redux/constants/userConstants'
import { PRE_REGISTER_USER_RESET } from 'local_redux/constants/preRegisterConstants'
import { getPreRegisterByEmail, registerPreRegisterUser } from 'local_redux/actions/preRegisterActions'
import styles from './styles/preRegisterInvestorScreenStyles'

const useStyles = makeStyles(styles)

const PreRegisterInvestorScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const search = useLocation().search
	const token = new URLSearchParams(search).get('code')

	const initialState = {
		address: '',
		bankAccountNumber: '',
		businessAddress: '',
		businessEmail: '',
		businessName: '',
		businessPhone: '',
		cif: '',
		dni: '',
		email: '',
		gender: '',
		lastName: '',
		name: '',
		nif: '',
		personType: '',
		phone: '',
		representativeLastName: '',
		representativeName: '',
		representativeNif: '',
		province: 'Madrid',
		location: 'Madrid',
		postalCode: '28001',
		country: '',
		bankAccountNumber: '',
		promotions: false,
		privacyPolicy: false,
	}

	const [data, setData] = useState(initialState)
	const [isPhysical, setIsPhysical] = useState(false)
	const [isLegal, setIsLegal] = useState(false)
	const [errorGender, setErrorGender] = useState('')
	const [errorAddress, setErrorAddress] = useState('')
	const [errorPrivacyPolicy, setErrorPrivacyPolicy] = useState('')
	const [errorPromotions, setErrorPromotions] = useState('')

	const { loadingPreRegisterUser, successPreRegisterUser, preRegisterUserData, errorPreRegisterUser } = useSelector(
		(state) => state.preRegisterUser,
	)
	const { loadingPreRegisterByEmail, successPreRegisterByEmail, errorPreRegisterByEmail, preRegisterByEmailData } =
		useSelector((state) => state.preRegisterByEmail)

	useEffect(() => {
		dispatch(getPreRegisterByEmail(token))
	}, [])
	useEffect(() => {
		if (errorPreRegisterByEmail) {
			navigate('/auth/login')
		} else if (successPreRegisterByEmail) {
			dispatch({ type: USER_LOGIN_SUCCESS, payload: preRegisterByEmailData })

			setData({ ...data, ...preRegisterByEmailData })
		}
	}, [errorPreRegisterByEmail, successPreRegisterByEmail])
	useEffect(() => {
		let timeOut
		if (successPreRegisterUser) {
			timeOut = setTimeout(() => {
				const decoded = parseJwt(preRegisterUserData.token)
				const userSession = {
					...decoded,
					token: preRegisterUserData.token,
				}
				dispatch({ type: USER_LOGIN_SUCCESS, payload: userSession })
				localStorage.setItem('setCHGG', JSON.stringify(preRegisterUserData.token))
				dispatch({ type: PRE_REGISTER_USER_RESET })
				navigate('/admin/user-page')
			}, 1000)
		}
		return () => clearTimeout(timeOut)
	}, [successPreRegisterUser])
	useEffect(() => {
		return () => dispatch({ type: PRE_REGISTER_USER_RESET })
	}, [dispatch])

	const handlePhysicalPerson = (e) => {
		setIsPhysical(e.target.checked)
		data.representativeName = ''
		data.representativeLastName = ''
		data.businessEmail = ''
		data.businessPhone = ''
		setData({ ...data, personType: 'physical' })
		if (isLegal) {
			setIsLegal(false)
		}
	}
	const handleLegalPerson = (e) => {
		setIsLegal(e.target.checked)
		data.representativeName = data?.name || ''
		data.representativeLastName = data?.lastName || ''
		data.businessEmail = data?.email || ''
		data.businessPhone = data?.phone || ''

		setData({ ...data, personType: 'legal' })
		if (isPhysical) {
			setIsPhysical(false)
		}
	}
	const registerInvestorHandler = (e) => {
		e.preventDefault()

		if (!data.gender) {
			return setErrorGender('Por favor ingrese el genero.')
		}
		if (!data.privacyPolicy) {
			return setErrorPrivacyPolicy('Por favor acepte la política de privacidad.')
		}
		if (!data.promotions) {
			return setErrorPromotions('Por favor acepte recibir información.')
		}

		if (data.address.includes(';')) {
			return setErrorAddress('La dirección no puede tener un ";".')
		}

		const dataToSend = {
			...data,
			gender: matchGender(data.gender),
			personType: decidePerson(isLegal, isPhysical),
		}

		dispatch(registerPreRegisterUser(dataToSend))
	}

	const personTypeValidator = isLegal || isPhysical

	return (
		<GridContainer>
			{loadingPreRegisterByEmail ? (
				<GridItem xs={2} className={classes.root}>
					<Loader />
				</GridItem>
			) : (
				successPreRegisterByEmail && (
					<GridItem xs={11} sm={11} md={10} className={classes.root}>
						<Card>
							<CardHeader color='primary' icon>
								<CardIcon color='primary'>
									<PersonAdd />
								</CardIcon>
								<h4 className={classes.cardTitle}>
									Registrar {matchPersonType(decidePerson(isLegal, isPhysical))} para Velzia Group - ShareHolders
								</h4>
							</CardHeader>
							<CardBody>
								<InvestorRegisterTitles personType={decidePerson(isLegal, isPhysical)} />
								<form autoComplete='off' onSubmit={registerInvestorHandler}>
									<GridContainer className={classes.formContainer}>
										<GridItem xs={12} md={6} className={classes.switchPersonType}>
											<FormControlLabel
												control={<Switch checked={isPhysical} onChange={handlePhysicalPerson} value='physical' />}
												label='Persona Física'
											/>
										</GridItem>
										<GridItem xs={12} md={6} className={classes.switchPersonType}>
											<FormControlLabel
												control={<Switch checked={isLegal} onChange={handleLegalPerson} value='legal' />}
												label='Persona Jurídica'
											/>
										</GridItem>
										{isPhysical && (
											<GridItem xs={12}>
												<div className={classes.formSectionContainer}>
													<InputsPhysicalProfile userInfo={data} setUserInfo={setData} />
												</div>
											</GridItem>
										)}
										{isLegal && (
											<GridItem xs={12}>
												<div className={classes.formSectionContainer}>
													<InputsUser userInfo={data} setUserInfo={setData} />
												</div>
												<h4 className={classes.companyInformation}>Introducir información de la empresa</h4>
												<div className={classes.formSectionContainer}>
													<InputsLegalProfile userInfo={data} setUserInfo={setData} />
												</div>
											</GridItem>
										)}
									</GridContainer>
									<GridContainer>
										{personTypeValidator && (
											<GridItem xs={12}>
												<FormGroup>
													<FormControlLabel
														control={
															<Checkbox
																checked={data.privacyPolicy}
																onChange={() => setData({ ...data, privacyPolicy: !data.privacyPolicy })}
																color='default'
															/>
														}
														label={
															<>
																He leído y acepto la
																<a
																	className={classes.privacyPolicyLink}
																	target='_blank'
																	href='https://c-g.es/politica-privacidad/'
																>
																	{' '}
																	política de privacidad.
																</a>
															</>
														}
													/>
													<FormControlLabel
														control={
															<Checkbox
																color='default'
																checked={data.promotions}
																onChange={() => setData({ ...data, promotions: !data.promotions })}
															/>
														}
														label='Acepto recibir información de nuestros proyectos e inmuebles, incluso por medios electrónicos.'
													/>
												</FormGroup>
											</GridItem>
										)}
										{errorPreRegisterUser && (
											<GridItem xs={12}>
												<SnackbarContent message={errorPreRegisterUser} color='danger' />
											</GridItem>
										)}
										{errorGender && !data.gender && (
											<GridItem xs={12}>
												<SnackbarContent message={errorGender} color='danger' />
											</GridItem>
										)}
										{errorAddress && !data.gender && (
											<GridItem xs={12}>
												<SnackbarContent message={errorAddress} color='danger' />
											</GridItem>
										)}
										{errorPrivacyPolicy && !data.privacyPolicy && (
											<GridItem xs={12}>
												<SnackbarContent message={errorPrivacyPolicy} color='danger' />
											</GridItem>
										)}
										{errorPromotions && !data.promotions && (
											<GridItem xs={12}>
												<SnackbarContent message={errorPromotions} color='danger' />
											</GridItem>
										)}
										{personTypeValidator && (
											<GridItem xs={12} className={classes.buttonRoot}>
												<Button
													disabled={loadingPreRegisterUser}
													type='submit'
													color={successPreRegisterUser ? `success` : 'primary'}
													block
												>
													{!successPreRegisterUser
														? loadingPreRegisterUser
															? 'Registrando...'
															: `Registrar ${matchPersonType(decidePerson(isLegal, isPhysical))}`
														: 'Registrado Correctamente'}
												</Button>
											</GridItem>
										)}
									</GridContainer>
								</form>
							</CardBody>
						</Card>
					</GridItem>
				)
			)}
		</GridContainer>
	)
}

export default PreRegisterInvestorScreen
